<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>
    <div class="container" v-else>
      <v-card
        width="95%"
        rounded="xl"
        flat
        color="grey lighten-4"
      >
        <v-card-text class="black--text py-2">
          {{post.body}}
        </v-card-text>
      </v-card>
    </div>
    <div class="container py-0">
      <div class="text-right text-caption grey--text font-italic">
        Latest comments at the top
        <v-icon color="grey" small>mdi-pin</v-icon>
      </div>
      <div
        v-if="comments.length && !loading"
      >
        <div
          v-for="c in comments"
          :key="c"
          class="d-flex justify-end"
        >
          <comment :comment="c" />
        </div>
      </div>
      <v-card
        v-else
        height="66vh"
        class="d-flex"
        flat
      >
        <v-card-text class="align-self-center text-center text-h6">There is no comments yet</v-card-text>
      </v-card>
      <v-btn
        v-if="!end"
        block
        rounded
        color="orange"
        class="my-10 white--text"
        text
        @click="more"
        :disabled="loading"
      >load more</v-btn>
      <v-btn
        v-else
        block
        rounded
        color="orange"
        class="my-10 white--text"
        text
        disabled
      >no more comments</v-btn>
    </div>
  </div>
</template>

<script>
import Comment from '../components/comments/Comment.vue'
import Loader from '../components/root/Loader.vue'

export default {
  components: { Comment, Loader },
  data: () => ({
    post: {},
    filteredComments: [],
    end: false
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    comments() {
      return this.$store.state.comments.comments
    },
    loading() {
      return this.$store.state.loading
    }
  },
  methods: {
    async getPost() {
      const p = await this.$store.dispatch('getThePost', this.id)
      this.post = p
      return p
    },
    async comments2() {
      const id = this.id
      await this.$store.dispatch('getComments', id)
    },
    async more() {
      const id = this.id
      const res = await this.$store.dispatch('loadMoreComments', id)
      if (!res) {
        this.end = true
      }
    },
  },
  async created() {
    this.$store.commit('startLoading')
    await this.getPost()
    await this.comments2()
    this.$store.commit('stopLoading')

  }
}
</script>

<style>

</style>