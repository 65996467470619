<template>
  <v-card
    width="95%"
    rounded="xl"
    flat
    color="grey lighten-4"
    class="mb-1"
  >
    <v-card-text class="black--text py-2 d-flex">
      {{ comment.body }}
      <div class="ml-auto justify-end" >
        <div v-if="reported" class="text-caption text-right font-weight-bold red--text">Reported</div>
        <v-btn v-else icon x-small color="red" @click="report"><v-icon x-small>mdi-virus</v-icon> </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    reported() {
      return this.reports.includes(this.comment.id)
    },
    reports() {
      return this.$store.state.reports
    }
  },
  methods: {
    report() {
      const data = {
        post: this.$route.params.id,
        comment: this.comment.id
      }
      this.$store.dispatch('reportComment', data)
    }
  },
  props: {
    comment: {
      type: Object
    },
  },
}
</script>

<style>

</style>